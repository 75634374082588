import { default as index5orPGiYGGnMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/2percenta-z-dani/index.vue?macro=true";
import { default as _91name_93VEnQ83EMvzMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/[name].vue?macro=true";
import { default as indexQiDNRqOdo5Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/index.vue?macro=true";
import { default as _91name_93j3sveTNvanMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/[name].vue?macro=true";
import { default as index3VRWIWOdH7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/index.vue?macro=true";
import { default as _91name_93nU9sbM9M6yMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/[name].vue?macro=true";
import { default as indexha9q2kyyxRMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/index.vue?macro=true";
import { default as duhova_45tolerancialPZ2nLDJseMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/duhova-tolerancia.vue?macro=true";
import { default as indexTLrJz6AVYzMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/index.vue?macro=true";
import { default as indexG2KYmiKOC7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/e-mailova-poradna-linky-dovery-nezabudka/index.vue?macro=true";
import { default as _91name_93k1EzPCnVzXMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/[name].vue?macro=true";
import { default as indexv694jcTSSsMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/index.vue?macro=true";
import { default as indexR0cGJ2SSJwMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/index.vue?macro=true";
import { default as _91name_936NALDlNtEnMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/[name].vue?macro=true";
import { default as indexTlGDtYDAnxMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/index.vue?macro=true";
import { default as dobroPyLJ8X43XPMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/dobro.vue?macro=true";
import { default as indexe7f1XQPQeaMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/index.vue?macro=true";
import { default as _91type_933gZFEScoPWMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/[type].vue?macro=true";
import { default as indexwbfqBTf44yMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/index.vue?macro=true";
import { default as _91name_93GYaX7EoDXQMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/[name].vue?macro=true";
import { default as indexyD7MjeznuYMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/index.vue?macro=true";
import { default as _91name_93GKqRcBmlAHMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/[name].vue?macro=true";
import { default as indexqd6WU0VtbQMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/index.vue?macro=true";
import { default as indexNfTbu4c33sMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/mapa-podpory/index.vue?macro=true";
import { default as clenovia_45ligyb0G6P38lMFMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/clenovia-ligy.vue?macro=true";
import { default as eticke_45kodexygjB4mlI3tkMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/eticke-kodexy.vue?macro=true";
import { default as kontakty1Mb33cadJ7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kontakty.vue?macro=true";
import { default as kto_45smecdbZd9pbz7Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kto-sme.vue?macro=true";
import { default as partneri_45a_45donoril9iNedZsn3Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/partneri-a-donori.vue?macro=true";
import { default as pre_45media5vcfsHrFx6Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/pre-media.vue?macro=true";
import { default as stanoviska_45ligyzTRNtwE7IlMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/stanoviska-ligy.vue?macro=true";
import { default as vyrocne_45spravyHFzUlckmzFMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/vyrocne-spravy.vue?macro=true";
import { default as indexUHx35nGbRHMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ocenenie-dobre-kruhy/index.vue?macro=true";
import { default as indexIPXsqCw5jmMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ochrana-osobnych-udajov/index.vue?macro=true";
import { default as _91name_93lxu3zkjhNUMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/organizacia/[name].vue?macro=true";
import { default as indexHyPQPREWVBMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/otazky-a-odpovede/index.vue?macro=true";
import { default as _91name_932XJ08XdSFQMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/[name].vue?macro=true";
import { default as indexDX1QMA3I5zMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/index.vue?macro=true";
import { default as _91name_931XPNjrUVKbMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/[name].vue?macro=true";
import { default as indexkUMaE5NMCFMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/index.vue?macro=true";
import { default as indexlm1cADe6GlMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podporte-nas/index.vue?macro=true";
import { default as _91name_93ALR0YL2vFgMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/[name].vue?macro=true";
import { default as indexaQatcMc1ujMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/index.vue?macro=true";
import { default as slavnostny_45koncert8TExj4nprzMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/slavnostny-koncert.vue?macro=true";
import { default as indexs4b68eziREMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/potrebujem-pomoc/index.vue?macro=true";
import { default as indexuwOEPoqqUIMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/preview/index.vue?macro=true";
import { default as _91name_93NJIFZS7LlwMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/[name].vue?macro=true";
import { default as indexMP0BBLTVePMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/index.vue?macro=true";
import { default as indexAQV1klCVWyMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/publikacie/index.vue?macro=true";
import { default as indexbasMcltAMUMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/staznosti-a-spatna-vazba/index.vue?macro=true";
import { default as _91name_93j2hQlKFL2rMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/[name].vue?macro=true";
import { default as indexXpe6c82Vy9Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/index.vue?macro=true";
import { default as indexNiTZyWiuRBMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/testy/index.vue?macro=true";
import { default as _91name_93n78gD5tIk0Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/[name].vue?macro=true";
import { default as index98uzpRRlT5Meta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/index.vue?macro=true";
import { default as _91categorySlug_93DC0ZET9JYnMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue?macro=true";
import { default as _91searchText_93O8881lo5JWMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyhladavanie/[searchText].vue?macro=true";
import { default as _91name_93BTmaHlo7HTMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/[name].vue?macro=true";
import { default as indexgCOvAIWbDYMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/index.vue?macro=true";
import { default as indexh7v17xlgCuMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzvy/index.vue?macro=true";
import { default as indexQhp10Nup7nMeta } from "/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/zbierka-dni-nezabudiek/index.vue?macro=true";
export default [
  {
    name: index5orPGiYGGnMeta?.name ?? "2percenta-z-dani",
    path: index5orPGiYGGnMeta?.path ?? "/2percenta-z-dani",
    meta: index5orPGiYGGnMeta || {},
    alias: index5orPGiYGGnMeta?.alias || [],
    redirect: index5orPGiYGGnMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/2percenta-z-dani/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93VEnQ83EMvzMeta?.name ?? "archiv-name",
    path: _91name_93VEnQ83EMvzMeta?.path ?? "/archiv/:name()",
    meta: _91name_93VEnQ83EMvzMeta || {},
    alias: _91name_93VEnQ83EMvzMeta?.alias || [],
    redirect: _91name_93VEnQ83EMvzMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/[name].vue").then(m => m.default || m)
  },
  {
    name: indexQiDNRqOdo5Meta?.name ?? "archiv",
    path: indexQiDNRqOdo5Meta?.path ?? "/archiv",
    meta: indexQiDNRqOdo5Meta || {},
    alias: indexQiDNRqOdo5Meta?.alias || [],
    redirect: indexQiDNRqOdo5Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/archiv/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93j3sveTNvanMeta?.name ?? "clanok-name",
    path: _91name_93j3sveTNvanMeta?.path ?? "/clanok/:name()",
    meta: _91name_93j3sveTNvanMeta || {},
    alias: _91name_93j3sveTNvanMeta?.alias || [],
    redirect: _91name_93j3sveTNvanMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/[name].vue").then(m => m.default || m)
  },
  {
    name: index3VRWIWOdH7Meta?.name ?? "clanok",
    path: index3VRWIWOdH7Meta?.path ?? "/clanok",
    meta: index3VRWIWOdH7Meta || {},
    alias: index3VRWIWOdH7Meta?.alias || [],
    redirect: index3VRWIWOdH7Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/clanok/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93nU9sbM9M6yMeta?.name ?? "diskusia-name",
    path: _91name_93nU9sbM9M6yMeta?.path ?? "/diskusia/:name()",
    meta: _91name_93nU9sbM9M6yMeta || {},
    alias: _91name_93nU9sbM9M6yMeta?.alias || [],
    redirect: _91name_93nU9sbM9M6yMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/[name].vue").then(m => m.default || m)
  },
  {
    name: indexha9q2kyyxRMeta?.name ?? "diskusia",
    path: indexha9q2kyyxRMeta?.path ?? "/diskusia",
    meta: indexha9q2kyyxRMeta || {},
    alias: indexha9q2kyyxRMeta?.alias || [],
    redirect: indexha9q2kyyxRMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusia/index.vue").then(m => m.default || m)
  },
  {
    name: duhova_45tolerancialPZ2nLDJseMeta?.name ?? "diskusie-duhova-tolerancia",
    path: duhova_45tolerancialPZ2nLDJseMeta?.path ?? "/diskusie/duhova-tolerancia",
    meta: duhova_45tolerancialPZ2nLDJseMeta || {},
    alias: duhova_45tolerancialPZ2nLDJseMeta?.alias || [],
    redirect: duhova_45tolerancialPZ2nLDJseMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/duhova-tolerancia.vue").then(m => m.default || m)
  },
  {
    name: indexTLrJz6AVYzMeta?.name ?? "diskusie",
    path: indexTLrJz6AVYzMeta?.path ?? "/diskusie",
    meta: indexTLrJz6AVYzMeta || {},
    alias: indexTLrJz6AVYzMeta?.alias || [],
    redirect: indexTLrJz6AVYzMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/diskusie/index.vue").then(m => m.default || m)
  },
  {
    name: indexG2KYmiKOC7Meta?.name ?? "e-mailova-poradna-linky-dovery-nezabudka",
    path: indexG2KYmiKOC7Meta?.path ?? "/e-mailova-poradna-linky-dovery-nezabudka",
    meta: indexG2KYmiKOC7Meta || {},
    alias: indexG2KYmiKOC7Meta?.alias || [],
    redirect: indexG2KYmiKOC7Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/e-mailova-poradna-linky-dovery-nezabudka/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93k1EzPCnVzXMeta?.name ?? "event-nezabudka-name",
    path: _91name_93k1EzPCnVzXMeta?.path ?? "/event-nezabudka/:name()",
    meta: _91name_93k1EzPCnVzXMeta || {},
    alias: _91name_93k1EzPCnVzXMeta?.alias || [],
    redirect: _91name_93k1EzPCnVzXMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/[name].vue").then(m => m.default || m)
  },
  {
    name: indexv694jcTSSsMeta?.name ?? "event-nezabudka",
    path: indexv694jcTSSsMeta?.path ?? "/event-nezabudka",
    meta: indexv694jcTSSsMeta || {},
    alias: indexv694jcTSSsMeta?.alias || [],
    redirect: indexv694jcTSSsMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/event-nezabudka/index.vue").then(m => m.default || m)
  },
  {
    name: indexR0cGJ2SSJwMeta?.name ?? "index",
    path: indexR0cGJ2SSJwMeta?.path ?? "/",
    meta: indexR0cGJ2SSJwMeta || {},
    alias: indexR0cGJ2SSJwMeta?.alias || [],
    redirect: indexR0cGJ2SSJwMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_936NALDlNtEnMeta?.name ?? "kampan-name",
    path: _91name_936NALDlNtEnMeta?.path ?? "/kampan/:name()",
    meta: _91name_936NALDlNtEnMeta || {},
    alias: _91name_936NALDlNtEnMeta?.alias || [],
    redirect: _91name_936NALDlNtEnMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/[name].vue").then(m => m.default || m)
  },
  {
    name: indexTlGDtYDAnxMeta?.name ?? "kampan",
    path: indexTlGDtYDAnxMeta?.path ?? "/kampan",
    meta: indexTlGDtYDAnxMeta || {},
    alias: indexTlGDtYDAnxMeta?.alias || [],
    redirect: indexTlGDtYDAnxMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampan/index.vue").then(m => m.default || m)
  },
  {
    name: dobroPyLJ8X43XPMeta?.name ?? "kampane-dobro",
    path: dobroPyLJ8X43XPMeta?.path ?? "/kampane/dobro",
    meta: dobroPyLJ8X43XPMeta || {},
    alias: dobroPyLJ8X43XPMeta?.alias || [],
    redirect: dobroPyLJ8X43XPMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/dobro.vue").then(m => m.default || m)
  },
  {
    name: indexe7f1XQPQeaMeta?.name ?? "kampane",
    path: indexe7f1XQPQeaMeta?.path ?? "/kampane",
    meta: indexe7f1XQPQeaMeta || {},
    alias: indexe7f1XQPQeaMeta?.alias || [],
    redirect: indexe7f1XQPQeaMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kampane/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_933gZFEScoPWMeta?.name ?? "kategoria-name-type",
    path: _91type_933gZFEScoPWMeta?.path ?? "/kategoria/:name()/:type()",
    meta: _91type_933gZFEScoPWMeta || {},
    alias: _91type_933gZFEScoPWMeta?.alias || [],
    redirect: _91type_933gZFEScoPWMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/[type].vue").then(m => m.default || m)
  },
  {
    name: indexwbfqBTf44yMeta?.name ?? "kategoria-name",
    path: indexwbfqBTf44yMeta?.path ?? "/kategoria/:name()",
    meta: indexwbfqBTf44yMeta || {},
    alias: indexwbfqBTf44yMeta?.alias || [],
    redirect: indexwbfqBTf44yMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kategoria/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93GYaX7EoDXQMeta?.name ?? "kurz-name",
    path: _91name_93GYaX7EoDXQMeta?.path ?? "/kurz/:name()",
    meta: _91name_93GYaX7EoDXQMeta || {},
    alias: _91name_93GYaX7EoDXQMeta?.alias || [],
    redirect: _91name_93GYaX7EoDXQMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/[name].vue").then(m => m.default || m)
  },
  {
    name: indexyD7MjeznuYMeta?.name ?? "kurz",
    path: indexyD7MjeznuYMeta?.path ?? "/kurz",
    meta: indexyD7MjeznuYMeta || {},
    alias: indexyD7MjeznuYMeta?.alias || [],
    redirect: indexyD7MjeznuYMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/kurz/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93GKqRcBmlAHMeta?.name ?? "letak-name",
    path: _91name_93GKqRcBmlAHMeta?.path ?? "/letak/:name()",
    meta: _91name_93GKqRcBmlAHMeta || {},
    alias: _91name_93GKqRcBmlAHMeta?.alias || [],
    redirect: _91name_93GKqRcBmlAHMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/[name].vue").then(m => m.default || m)
  },
  {
    name: indexqd6WU0VtbQMeta?.name ?? "letak",
    path: indexqd6WU0VtbQMeta?.path ?? "/letak",
    meta: indexqd6WU0VtbQMeta || {},
    alias: indexqd6WU0VtbQMeta?.alias || [],
    redirect: indexqd6WU0VtbQMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/letak/index.vue").then(m => m.default || m)
  },
  {
    name: indexNfTbu4c33sMeta?.name ?? "mapa-podpory",
    path: indexNfTbu4c33sMeta?.path ?? "/mapa-podpory",
    meta: indexNfTbu4c33sMeta || {},
    alias: indexNfTbu4c33sMeta?.alias || [],
    redirect: indexNfTbu4c33sMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/mapa-podpory/index.vue").then(m => m.default || m)
  },
  {
    name: clenovia_45ligyb0G6P38lMFMeta?.name ?? "o-nas-clenovia-ligy",
    path: clenovia_45ligyb0G6P38lMFMeta?.path ?? "/o-nas/clenovia-ligy",
    meta: clenovia_45ligyb0G6P38lMFMeta || {},
    alias: clenovia_45ligyb0G6P38lMFMeta?.alias || [],
    redirect: clenovia_45ligyb0G6P38lMFMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/clenovia-ligy.vue").then(m => m.default || m)
  },
  {
    name: eticke_45kodexygjB4mlI3tkMeta?.name ?? "o-nas-eticke-kodexy",
    path: eticke_45kodexygjB4mlI3tkMeta?.path ?? "/o-nas/eticke-kodexy",
    meta: eticke_45kodexygjB4mlI3tkMeta || {},
    alias: eticke_45kodexygjB4mlI3tkMeta?.alias || [],
    redirect: eticke_45kodexygjB4mlI3tkMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/eticke-kodexy.vue").then(m => m.default || m)
  },
  {
    name: kontakty1Mb33cadJ7Meta?.name ?? "o-nas-kontakty",
    path: kontakty1Mb33cadJ7Meta?.path ?? "/o-nas/kontakty",
    meta: kontakty1Mb33cadJ7Meta || {},
    alias: kontakty1Mb33cadJ7Meta?.alias || [],
    redirect: kontakty1Mb33cadJ7Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kontakty.vue").then(m => m.default || m)
  },
  {
    name: kto_45smecdbZd9pbz7Meta?.name ?? "o-nas-kto-sme",
    path: kto_45smecdbZd9pbz7Meta?.path ?? "/o-nas/kto-sme",
    meta: kto_45smecdbZd9pbz7Meta || {},
    alias: kto_45smecdbZd9pbz7Meta?.alias || [],
    redirect: kto_45smecdbZd9pbz7Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/kto-sme.vue").then(m => m.default || m)
  },
  {
    name: partneri_45a_45donoril9iNedZsn3Meta?.name ?? "o-nas-partneri-a-donori",
    path: partneri_45a_45donoril9iNedZsn3Meta?.path ?? "/o-nas/partneri-a-donori",
    meta: partneri_45a_45donoril9iNedZsn3Meta || {},
    alias: partneri_45a_45donoril9iNedZsn3Meta?.alias || [],
    redirect: partneri_45a_45donoril9iNedZsn3Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/partneri-a-donori.vue").then(m => m.default || m)
  },
  {
    name: pre_45media5vcfsHrFx6Meta?.name ?? "o-nas-pre-media",
    path: pre_45media5vcfsHrFx6Meta?.path ?? "/o-nas/pre-media",
    meta: pre_45media5vcfsHrFx6Meta || {},
    alias: pre_45media5vcfsHrFx6Meta?.alias || [],
    redirect: pre_45media5vcfsHrFx6Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/pre-media.vue").then(m => m.default || m)
  },
  {
    name: stanoviska_45ligyzTRNtwE7IlMeta?.name ?? "o-nas-stanoviska-ligy",
    path: stanoviska_45ligyzTRNtwE7IlMeta?.path ?? "/o-nas/stanoviska-ligy",
    meta: stanoviska_45ligyzTRNtwE7IlMeta || {},
    alias: stanoviska_45ligyzTRNtwE7IlMeta?.alias || [],
    redirect: stanoviska_45ligyzTRNtwE7IlMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/stanoviska-ligy.vue").then(m => m.default || m)
  },
  {
    name: vyrocne_45spravyHFzUlckmzFMeta?.name ?? "o-nas-vyrocne-spravy",
    path: vyrocne_45spravyHFzUlckmzFMeta?.path ?? "/o-nas/vyrocne-spravy",
    meta: vyrocne_45spravyHFzUlckmzFMeta || {},
    alias: vyrocne_45spravyHFzUlckmzFMeta?.alias || [],
    redirect: vyrocne_45spravyHFzUlckmzFMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/o-nas/vyrocne-spravy.vue").then(m => m.default || m)
  },
  {
    name: indexUHx35nGbRHMeta?.name ?? "ocenenie-dobre-kruhy",
    path: indexUHx35nGbRHMeta?.path ?? "/ocenenie-dobre-kruhy",
    meta: indexUHx35nGbRHMeta || {},
    alias: indexUHx35nGbRHMeta?.alias || [],
    redirect: indexUHx35nGbRHMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ocenenie-dobre-kruhy/index.vue").then(m => m.default || m)
  },
  {
    name: indexIPXsqCw5jmMeta?.name ?? "ochrana-osobnych-udajov",
    path: indexIPXsqCw5jmMeta?.path ?? "/ochrana-osobnych-udajov",
    meta: indexIPXsqCw5jmMeta || {},
    alias: indexIPXsqCw5jmMeta?.alias || [],
    redirect: indexIPXsqCw5jmMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/ochrana-osobnych-udajov/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93lxu3zkjhNUMeta?.name ?? "organizacia-name",
    path: _91name_93lxu3zkjhNUMeta?.path ?? "/organizacia/:name()",
    meta: _91name_93lxu3zkjhNUMeta || {},
    alias: _91name_93lxu3zkjhNUMeta?.alias || [],
    redirect: _91name_93lxu3zkjhNUMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/organizacia/[name].vue").then(m => m.default || m)
  },
  {
    name: indexHyPQPREWVBMeta?.name ?? "otazky-a-odpovede",
    path: indexHyPQPREWVBMeta?.path ?? "/otazky-a-odpovede",
    meta: indexHyPQPREWVBMeta || {},
    alias: indexHyPQPREWVBMeta?.alias || [],
    redirect: indexHyPQPREWVBMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/otazky-a-odpovede/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_932XJ08XdSFQMeta?.name ?? "peerovia-name",
    path: _91name_932XJ08XdSFQMeta?.path ?? "/peerovia/:name()",
    meta: _91name_932XJ08XdSFQMeta || {},
    alias: _91name_932XJ08XdSFQMeta?.alias || [],
    redirect: _91name_932XJ08XdSFQMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/[name].vue").then(m => m.default || m)
  },
  {
    name: indexDX1QMA3I5zMeta?.name ?? "peerovia",
    path: indexDX1QMA3I5zMeta?.path ?? "/peerovia",
    meta: indexDX1QMA3I5zMeta || {},
    alias: indexDX1QMA3I5zMeta?.alias || [],
    redirect: indexDX1QMA3I5zMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/peerovia/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_931XPNjrUVKbMeta?.name ?? "podcast-name",
    path: _91name_931XPNjrUVKbMeta?.path ?? "/podcast/:name()",
    meta: _91name_931XPNjrUVKbMeta || {},
    alias: _91name_931XPNjrUVKbMeta?.alias || [],
    redirect: _91name_931XPNjrUVKbMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/[name].vue").then(m => m.default || m)
  },
  {
    name: indexkUMaE5NMCFMeta?.name ?? "podcast",
    path: indexkUMaE5NMCFMeta?.path ?? "/podcast",
    meta: indexkUMaE5NMCFMeta || {},
    alias: indexkUMaE5NMCFMeta?.alias || [],
    redirect: indexkUMaE5NMCFMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podcast/index.vue").then(m => m.default || m)
  },
  {
    name: indexlm1cADe6GlMeta?.name ?? "podporte-nas",
    path: indexlm1cADe6GlMeta?.path ?? "/podporte-nas",
    meta: indexlm1cADe6GlMeta || {},
    alias: indexlm1cADe6GlMeta?.alias || [],
    redirect: indexlm1cADe6GlMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podporte-nas/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93ALR0YL2vFgMeta?.name ?? "podujatie-name",
    path: _91name_93ALR0YL2vFgMeta?.path ?? "/podujatie/:name()",
    meta: _91name_93ALR0YL2vFgMeta || {},
    alias: _91name_93ALR0YL2vFgMeta?.alias || [],
    redirect: _91name_93ALR0YL2vFgMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/[name].vue").then(m => m.default || m)
  },
  {
    name: indexaQatcMc1ujMeta?.name ?? "podujatie",
    path: indexaQatcMc1ujMeta?.path ?? "/podujatie",
    meta: indexaQatcMc1ujMeta || {},
    alias: indexaQatcMc1ujMeta?.alias || [],
    redirect: indexaQatcMc1ujMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/index.vue").then(m => m.default || m)
  },
  {
    name: slavnostny_45koncert8TExj4nprzMeta?.name ?? "podujatie-slavnostny-koncert",
    path: slavnostny_45koncert8TExj4nprzMeta?.path ?? "/podujatie/slavnostny-koncert",
    meta: slavnostny_45koncert8TExj4nprzMeta || {},
    alias: slavnostny_45koncert8TExj4nprzMeta?.alias || [],
    redirect: slavnostny_45koncert8TExj4nprzMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/podujatie/slavnostny-koncert.vue").then(m => m.default || m)
  },
  {
    name: indexs4b68eziREMeta?.name ?? "potrebujem-pomoc",
    path: indexs4b68eziREMeta?.path ?? "/potrebujem-pomoc",
    meta: indexs4b68eziREMeta || {},
    alias: indexs4b68eziREMeta?.alias || [],
    redirect: indexs4b68eziREMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/potrebujem-pomoc/index.vue").then(m => m.default || m)
  },
  {
    name: indexuwOEPoqqUIMeta?.name ?? "preview",
    path: indexuwOEPoqqUIMeta?.path ?? "/preview",
    meta: indexuwOEPoqqUIMeta || {},
    alias: indexuwOEPoqqUIMeta?.alias || [],
    redirect: indexuwOEPoqqUIMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93NJIFZS7LlwMeta?.name ?? "projekt-name",
    path: _91name_93NJIFZS7LlwMeta?.path ?? "/projekt/:name()",
    meta: _91name_93NJIFZS7LlwMeta || {},
    alias: _91name_93NJIFZS7LlwMeta?.alias || [],
    redirect: _91name_93NJIFZS7LlwMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/[name].vue").then(m => m.default || m)
  },
  {
    name: indexMP0BBLTVePMeta?.name ?? "projekt",
    path: indexMP0BBLTVePMeta?.path ?? "/projekt",
    meta: indexMP0BBLTVePMeta || {},
    alias: indexMP0BBLTVePMeta?.alias || [],
    redirect: indexMP0BBLTVePMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/projekt/index.vue").then(m => m.default || m)
  },
  {
    name: indexAQV1klCVWyMeta?.name ?? "publikacie",
    path: indexAQV1klCVWyMeta?.path ?? "/publikacie",
    meta: indexAQV1klCVWyMeta || {},
    alias: indexAQV1klCVWyMeta?.alias || [],
    redirect: indexAQV1klCVWyMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/publikacie/index.vue").then(m => m.default || m)
  },
  {
    name: indexbasMcltAMUMeta?.name ?? "staznosti-a-spatna-vazba",
    path: indexbasMcltAMUMeta?.path ?? "/staznosti-a-spatna-vazba",
    meta: indexbasMcltAMUMeta || {},
    alias: indexbasMcltAMUMeta?.alias || [],
    redirect: indexbasMcltAMUMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/staznosti-a-spatna-vazba/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93j2hQlKFL2rMeta?.name ?? "stranka-name",
    path: _91name_93j2hQlKFL2rMeta?.path ?? "/stranka/:name()",
    meta: _91name_93j2hQlKFL2rMeta || {},
    alias: _91name_93j2hQlKFL2rMeta?.alias || [],
    redirect: _91name_93j2hQlKFL2rMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/[name].vue").then(m => m.default || m)
  },
  {
    name: indexXpe6c82Vy9Meta?.name ?? "stranka",
    path: indexXpe6c82Vy9Meta?.path ?? "/stranka",
    meta: indexXpe6c82Vy9Meta || {},
    alias: indexXpe6c82Vy9Meta?.alias || [],
    redirect: indexXpe6c82Vy9Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/stranka/index.vue").then(m => m.default || m)
  },
  {
    name: indexNiTZyWiuRBMeta?.name ?? "testy",
    path: indexNiTZyWiuRBMeta?.path ?? "/testy",
    meta: indexNiTZyWiuRBMeta || {},
    alias: indexNiTZyWiuRBMeta?.alias || [],
    redirect: indexNiTZyWiuRBMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/testy/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93n78gD5tIk0Meta?.name ?? "video-name",
    path: _91name_93n78gD5tIk0Meta?.path ?? "/video/:name()",
    meta: _91name_93n78gD5tIk0Meta || {},
    alias: _91name_93n78gD5tIk0Meta?.alias || [],
    redirect: _91name_93n78gD5tIk0Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/[name].vue").then(m => m.default || m)
  },
  {
    name: index98uzpRRlT5Meta?.name ?? "video",
    path: index98uzpRRlT5Meta?.path ?? "/video",
    meta: index98uzpRRlT5Meta || {},
    alias: index98uzpRRlT5Meta?.alias || [],
    redirect: index98uzpRRlT5Meta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: _91categorySlug_93DC0ZET9JYnMeta?.name ?? "vsetky-vysledky-slug-categorySlug",
    path: _91categorySlug_93DC0ZET9JYnMeta?.path ?? "/vsetky-vysledky/:slug()/:categorySlug()",
    meta: _91categorySlug_93DC0ZET9JYnMeta || {},
    alias: _91categorySlug_93DC0ZET9JYnMeta?.alias || [],
    redirect: _91categorySlug_93DC0ZET9JYnMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue").then(m => m.default || m)
  },
  {
    name: _91searchText_93O8881lo5JWMeta?.name ?? "vyhladavanie-searchText",
    path: _91searchText_93O8881lo5JWMeta?.path ?? "/vyhladavanie/:searchText()",
    meta: _91searchText_93O8881lo5JWMeta || {},
    alias: _91searchText_93O8881lo5JWMeta?.alias || [],
    redirect: _91searchText_93O8881lo5JWMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyhladavanie/[searchText].vue").then(m => m.default || m)
  },
  {
    name: _91name_93BTmaHlo7HTMeta?.name ?? "vyzva-name",
    path: _91name_93BTmaHlo7HTMeta?.path ?? "/vyzva/:name()",
    meta: _91name_93BTmaHlo7HTMeta || {},
    alias: _91name_93BTmaHlo7HTMeta?.alias || [],
    redirect: _91name_93BTmaHlo7HTMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/[name].vue").then(m => m.default || m)
  },
  {
    name: indexgCOvAIWbDYMeta?.name ?? "vyzva",
    path: indexgCOvAIWbDYMeta?.path ?? "/vyzva",
    meta: indexgCOvAIWbDYMeta || {},
    alias: indexgCOvAIWbDYMeta?.alias || [],
    redirect: indexgCOvAIWbDYMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzva/index.vue").then(m => m.default || m)
  },
  {
    name: indexh7v17xlgCuMeta?.name ?? "vyzvy",
    path: indexh7v17xlgCuMeta?.path ?? "/vyzvy",
    meta: indexh7v17xlgCuMeta || {},
    alias: indexh7v17xlgCuMeta?.alias || [],
    redirect: indexh7v17xlgCuMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vyzvy/index.vue").then(m => m.default || m)
  },
  {
    name: indexQhp10Nup7nMeta?.name ?? "zbierka-dni-nezabudiek",
    path: indexQhp10Nup7nMeta?.path ?? "/zbierka-dni-nezabudiek",
    meta: indexQhp10Nup7nMeta || {},
    alias: indexQhp10Nup7nMeta?.alias || [],
    redirect: indexQhp10Nup7nMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/zbierka-dni-nezabudiek/index.vue").then(m => m.default || m)
  },
  {
    name: _91categorySlug_93DC0ZET9JYnMeta?.name ?? "vsetky-vysledky",
    path: _91categorySlug_93DC0ZET9JYnMeta?.path ?? "/vsetky-vysledky/:slug",
    meta: _91categorySlug_93DC0ZET9JYnMeta || {},
    alias: _91categorySlug_93DC0ZET9JYnMeta?.alias || [],
    redirect: _91categorySlug_93DC0ZET9JYnMeta?.redirect,
    component: () => import("/Users/pavolrasev/Develop/sites/habitable/ligapredusevnezdravie/src/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue").then(m => m.default || m)
  }
]